import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "add-user-controls-for-data-slicing-and-dicing"
    }}>{`Add user controls for data slicing and dicing`}</h1>
    <Vimeo id={432211014} mdxType="Vimeo" />
    <p>{`Now comes the fun part. All that extra effort we put into making our components
aware of filtering, and it all comes down to this: User controls.`}</p>
    <p>{`Here's what we're building:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/controls.png",
        "alt": "User controlled filters"
      }}></img></p>
    <p>{`It's a set of filters for users to slice and dice our visualization. The
shortened dataset gives you 2 years, 12 job titles, and 50 US states. You'll
get 5+ years and many more job titles with the full dataset.`}</p>
    <p>{`We're using the
`}<a parentName="p" {...{
        "href": "/state-app-structure"
      }}>{`architecture we discussed`}</a>{`
earlier to make it work. Clicking buttons updates a filter function and
communicates it all the way up to the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component. `}<inlineCode parentName="p">{`App`}</inlineCode>{` then uses it to
update `}<inlineCode parentName="p">{`this.state.filteredSalaries`}</inlineCode>{`, which triggers a re-render and updates
our dataviz.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/architecture_callbacks.png",
        "alt": "Architecture sketch"
      }}></img></p>
    <p>{`We're building controls in 4 steps, top to bottom:`}</p>
    <ol>
      <li parentName="ol">{`Update `}<inlineCode parentName="li">{`App.js`}</inlineCode>{` with filtering and a `}<inlineCode parentName="li">{`<Controls>`}</inlineCode>{` render`}</li>
      <li parentName="ol">{`Build a `}<inlineCode parentName="li">{`Controls`}</inlineCode>{` component, which builds the filter based on inputs`}</li>
      <li parentName="ol">{`Build a `}<inlineCode parentName="li">{`ControlRow`}</inlineCode>{` component, which handles a row of buttons`}</li>
      <li parentName="ol">{`Build a `}<inlineCode parentName="li">{`Toggle`}</inlineCode>{` component, which is a button`}</li>
    </ol>
    <p>{`We'll go through the files linearly. That makes them easier for me to explain
and easier for you to understand, but that also means there's going to be a
long period where all you're seeing is an error like this:`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/controls-error.png",
        "alt": "Controls error during coding"
      }}></img></p>
    <p>{`If you want to see what's up during this process, remove an import or two and
maybe a thing from render. For instance, it's complaining about `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{` in
this screenshot. Remove the `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{` import on top and delete
`}<inlineCode parentName="p">{`<ControlRow ... />`}</inlineCode>{` from render. The error goes away, and you see what you're
doing.`}</p>
    <h2 {...{
      "id": "step-1-update-appjs"
    }}>{`Step 1: Update App.js`}</h2>
    <Vimeo id={432211494} mdxType="Vimeo" />
    <p>{`All right, you know the drill. Add imports, tweak some things, add to render.
We have to import `}<inlineCode parentName="p">{`Controls`}</inlineCode>{`, set up filtering, update the map's `}<inlineCode parentName="p">{`zoom`}</inlineCode>{` prop,
and render a white rectangle and `}<inlineCode parentName="p">{`Controls`}</inlineCode>{`.`}</p>
    <p>{`The white rectangle makes it so the zoomed-in map doesn't cover up the
histogram. I'll explain when we get there.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
import MedianLine from "./components/MedianLine"

// Insert the line(s) between here...
import Controls from "./components/Controls"
// ...and here.

class App extends React.Component {
    // Insert the line(s) between here...
    const [salariesFilter, setSalariesFilter] = useState(() => () => true);
    // ...and here.
    const [filteredBy, setFilteredBy] = useState({
        USstate: "*",
        year: "*",
        jobTitle: "*",
    });


  // ...

  // Insert the line(s) between here...
  function updateDataFilter(filter, filteredBy) {
        setFilteredBy(filteredBy);
        setSalariesFilter(() => filter);
    }
  // ...and here.

  render() {
    // ...
  }
}
`}</code></pre>
    <p>{`We import the `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` component and add a default `}<inlineCode parentName="p">{`salariesFilter`}</inlineCode>{` function
to `}<inlineCode parentName="p">{`this.state`}</inlineCode>{`. The `}<inlineCode parentName="p">{`updateDataFilter`}</inlineCode>{` method passes the filter function and
`}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` dictionary from arguments to App state. We'll use it as a callback
in `}<inlineCode parentName="p">{`Controls`}</inlineCode>{`.`}</p>
    <p>{`The rest of filtering setup happens in the render method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
function App() {
    // ...

        // ...
        // Delete the line(s) between here...
        const filteredSalaries = techSalaries
        // ...and here.
        // Insert the line(s) between here...
        const filteredSalaries = techSalaries
                                     .filter(salariesFilter)
        // ...and here.

        // ...

        let zoom = null,
            medianHousehold = // ...
        // Insert the line(s) between here...
        if (filteredBy.USstate !== "*") {
            zoom = filteredBy.USstate;
            medianHousehold = d3.mean(
                medianIncomesByUSState[zoom],
                (d) => d.medianIncome
            );
        }
        // ...and here.

        // ...
    }
}
`}</code></pre>
    <p>{`We add a `}<inlineCode parentName="p">{`.filter`}</inlineCode>{` call to `}<inlineCode parentName="p">{`filteredSalaries`}</inlineCode>{`, which uses our `}<inlineCode parentName="p">{`salariesFilter`}</inlineCode>{`
method to throw out anything that doesn't fit. Then we set up `}<inlineCode parentName="p">{`zoom`}</inlineCode>{`, if a US
state was selected.`}</p>
    <p>{`We built the `}<inlineCode parentName="p">{`CountyMap`}</inlineCode>{` component to focus on a given US state. Finding the
centroid of a polygon, re-centering the map, and increasing the sizing factor.
It creates a nice zoom effect.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/zoom-effect.png",
        "alt": "Zoom effect"
      }}></img></p>
    <p>{`And here's the downside of this approach. SVG doesn't know about element
boundaries. It just renders stuff.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/zoom-without-rectangle.png",
        "alt": "Zoom without white rectangle"
      }}></img></p>
    <p>{`See, it goes under the histogram. Let's fix that and add the `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` render
while we're at it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
function App() {
    // ...

        // ...

        return (
            <div //...>
                <svg //...>
                    <CountyMap //... />

                    // Insert the line(s) between here...
                    <rect x="500" y="0"
                          width="600"
                          height="500"
                          style={{fill: 'white'}} />
                    // ...and here.

                    <Histogram //... />
                    <MedianLine //.. />
                </svg>

                // Insert the line(s) between here...
                <Controls data={techSalaries}
                          updateDataFilter={updateDataFilter} />
                // ...and here.
            </div>
        )
    }
}
`}</code></pre>
    <p>{`Rectangle, `}<inlineCode parentName="p">{`500`}</inlineCode>{` to the right, `}<inlineCode parentName="p">{`0`}</inlineCode>{` from top, `}<inlineCode parentName="p">{`600`}</inlineCode>{` wide and `}<inlineCode parentName="p">{`500`}</inlineCode>{` tall, with a
white background. Gives the histogram an opaque background, so it doesn't
matter what the map is doing.`}</p>
    <p>{`We render the `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` component just after `}<inlineCode parentName="p">{`</svg>`}</inlineCode>{` because it's not an SVG
component – it uses normal HTML. Unlike other components, it needs our entire
dataset as `}<inlineCode parentName="p">{`data`}</inlineCode>{`. We use the `}<inlineCode parentName="p">{`updateDataFilter`}</inlineCode>{` prop to say which callback
function it should call when a new filter is ready.`}</p>
    <p>{`If this seems roundabout ... I've seen worse. The callbacks approach makes our
app easier to componentize and keeps the code relatively unmessy. Imagine
putting everything we've done so far in `}<inlineCode parentName="p">{`App`}</inlineCode>{`! :satisfied:`}</p>
    <h2 {...{
      "id": "step-2-build-controls-component"
    }}>{`Step 2: Build Controls component`}</h2>
    <Vimeo id={432212029} mdxType="Vimeo" />
    <p>{`The `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` component builds our filter function and `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` dictionary
based on user choices.`}</p>
    <p><inlineCode parentName="p">{`Controls`}</inlineCode>{` renders 3 rows of buttons and builds filtering out of the choice
made on each row. That makes `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` kind of repetitive, but that's okay.`}</p>
    <p>{`To keep this book shorter, we're going to build everything for a `}<inlineCode parentName="p">{`year`}</inlineCode>{` filter
first. Then I'll explain how to add `}<inlineCode parentName="p">{`USstate`}</inlineCode>{` and `}<inlineCode parentName="p">{`jobTitle`}</inlineCode>{` filters on a
higher level. Once you have one working, the rest follows that same pattern.`}</p>
    <p>{`Make a `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` directory in `}<inlineCode parentName="p">{`src/components/`}</inlineCode>{` and let's begin. The main
`}<inlineCode parentName="p">{`Controls`}</inlineCode>{` component goes in your `}<inlineCode parentName="p">{`index.js`}</inlineCode>{` file.`}</p>
    <h3 {...{
      "id": "stub-controls"
    }}>{`Stub Controls`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js
import React from "react"

import ControlRow from "./ControlRow"

const Controls = ({ data, updateDataFilter }) => {
  const [filteredBy, setFilteredBy] = useState({
    year: "*",
  })
  const [filterFunctions, setFilter] = useState({
    year: () => true,
  })

  const updateYearFilter = (year, reset) => {}
}

export default Controls
`}</code></pre>
    <p>{`We start with some imports and a `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` class-based component. Inside, we
define default `}<inlineCode parentName="p">{`state`}</inlineCode>{` with an always-true `}<inlineCode parentName="p">{`yearFilter`}</inlineCode>{` and an asterisk for
`}<inlineCode parentName="p">{`year`}</inlineCode>{`.`}</p>
    <p>{`We also need an `}<inlineCode parentName="p">{`updateYearFilter`}</inlineCode>{` function, which we'll use to update the
filter, a `}<inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{` function, and a `}<inlineCode parentName="p">{`render`}</inlineCode>{` method. We're using
`}<inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{` to bubble updates to our parent component. It's a
simpler alternative to using React Context or a state management library.`}</p>
    <h3 {...{
      "id": "filter-logic"
    }}>{`Filter logic`}</h3>
    <Vimeo id={432212799} mdxType="Vimeo" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js
const Controls = (...) => {
  // ...
  const updateYearFilter = (year, reset) => {
    let yearFilter = (d) => d.submit_date.getFullYear() === year

    if (reset || !year) {
      yearFilter = () => true
      year = "*"
    }

    setFilteredBy((filteredBy) => {
      return { ...filteredBy, year }
    })
    setFilter((filterFunctions) => {
      return { ...filterFunctions, year: yearFilter }
    })
  }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`updateYearFilter`}</inlineCode>{` is a callback we pass into `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{`. When a user picks a
year, their action triggers this function.`}</p>
    <p>{`When that happens, we create a new partial filter function. The `}<inlineCode parentName="p">{`App`}</inlineCode>{` component
uses it inside a `}<inlineCode parentName="p">{`.filter`}</inlineCode>{` call like you saw earlier. We have to return `}<inlineCode parentName="p">{`true`}</inlineCode>{`
for elements we want to keep and `}<inlineCode parentName="p">{`false`}</inlineCode>{` for elements we don't.`}</p>
    <p>{`Comparing `}<inlineCode parentName="p">{`submit_date.getFullYear()`}</inlineCode>{` with `}<inlineCode parentName="p">{`year`}</inlineCode>{` achieves that.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`reset`}</inlineCode>{` argument lets us reset filters back to defaults. Enables users to
unselect options.`}</p>
    <p>{`When we have the `}<inlineCode parentName="p">{`year`}</inlineCode>{` and `}<inlineCode parentName="p">{`filter`}</inlineCode>{`, we update component state with
`}<inlineCode parentName="p">{`this.setState`}</inlineCode>{`. This triggers a re-render and calls `}<inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{`
afterwards. Great use-case for the little known setState callback 😃`}</p>
    <p><inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{` then calls `}<inlineCode parentName="p">{`this.props.updateDataFilter`}</inlineCode>{`, which is a
callback method on `}<inlineCode parentName="p">{`App`}</inlineCode>{`. We defined it earlier – it needs a new filter method
and a `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` dictionary.`}</p>
    <pre><code parentName="pre" {...{}}>{`// src/components/Controls.js
const Controls = ( ... ) => {
    // ...
    function reportUpdateUpTheChain() {
        window.location.hash = [
            filteredBy.year
        ].join("-");

        const filter = (d) =>
            filterFunctions.year(d)

        updateDataFilter(filter, filteredBy);
    }
}
`}</code></pre>
    <p>{`Building the filter method looks tricky because we're composing multiple
functions. The new arrow function takes a dictionary of filters as an argument
and returns a new function that `}<inlineCode parentName="p">{`&&`}</inlineCode>{`s them all. We invoke it immediately with
`}<inlineCode parentName="p">{`this.state`}</inlineCode>{` as the argument.`}</p>
    <p>{`It looks silly when there's just one filter, but I promise it makes sense.`}</p>
    <h3 {...{
      "id": "render"
    }}>{`Render`}</h3>
    <p>{`Great, we have the filter logic. Let's render those rows of controls we've been
talking about.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls/index.js
const Controls = (...) => {
  // ...
    const years = new Set(data.map((d) => d.submit_date.getFullYear()));

    return (
      <div>
         <ControlRow
                data={data}
                toggleNames={Array.from(years.values())}
                picked={filteredBy.year}
                updateDataFilter={updateYearFilter}
            />
      </div>
    )

}
`}</code></pre>
    <p>{`Once more, this is generalized code used for a single example: the `}<inlineCode parentName="p">{`year`}</inlineCode>{`
filter.`}</p>
    <p>{`We build a `}<inlineCode parentName="p">{`Set`}</inlineCode>{` of distinct years in our dataset, then render a `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{`
using props to give it our `}<inlineCode parentName="p">{`data`}</inlineCode>{`, a set of `}<inlineCode parentName="p">{`toggleNames`}</inlineCode>{`, a callback to update
the filter, and which entry is `}<inlineCode parentName="p">{`picked`}</inlineCode>{` right now. Also known as the controlled
component pattern, it enables us to maintain the data-flows-down,
events-bubble-up architecture.`}</p>
    <p>{`If you don't know about `}<inlineCode parentName="p">{`Set`}</inlineCode>{`s, they're an ES6 data structure that ensures
every entry is unique. Just like a mathematical set. They're pretty fast.`}</p>
    <h2 {...{
      "id": "step-3-build-controlrow-component"
    }}>{`Step 3: Build ControlRow component`}</h2>
    <Vimeo id={432213165} mdxType="Vimeo" />
    <p>{`Let's build the `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{` component. It renders a row of controls and
ensures only one at a time is selected.`}</p>
    <p>{`We'll start with a stub and go from there.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js
import React from "react"

const ControlRow = ({
  data,
  toggleNames,
  picked,
  updateDataFilter,
  capitalize,
}) => {
  function makePick(picked, newState) {
    updateDataFilter(picked, !newState)
  }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`makePick`}</inlineCode>{` calls the data filter update and passes in the new value and whether
we want to unselect. Pretty simple right?`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js
const ControlRow = () => {
  // ...

  return (
    <div className="row">
      <div className="col-md-12">
        {toggleNames.map((name) => (
          <Toggle
            label={capitalize ? name.toUpperCase() : name}
            name={name}
            key={name}
            value={picked === name}
            onClick={makePick}
          />
        ))}
      </div>
    </div>
  )
}
`}</code></pre>
    <p>{`In render, we set up two `}<inlineCode parentName="p">{`div`}</inlineCode>{`s with Bootstrap classes. The first is a `}<inlineCode parentName="p">{`row`}</inlineCode>{`,
and the second is a full-width column. I tried using a column for each button,
but it was annoying to manage and used too much space.`}</p>
    <p>{`Inside the divs, we map over all toggles and use the `}<inlineCode parentName="p">{`<Toggle>`}</inlineCode>{` component to render each of them. The label is a prettier version of the name, which
also serves as a key in our `}<inlineCode parentName="p">{`toggleValues`}</inlineCode>{` dictionary. It's going to be the
`}<inlineCode parentName="p">{`picked`}</inlineCode>{` attribute in `}<inlineCode parentName="p">{`makePick`}</inlineCode>{`.`}</p>
    <p>{`Your browser should continue showing an error, but it should change to talking
about the `}<inlineCode parentName="p">{`Toggle`}</inlineCode>{` component instead of `}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/toggle-error.png",
        "alt": null
      }}></img></p>
    <p>{`Let's build it.`}</p>
    <h2 {...{
      "id": "step-5-add-us-state-and-job-title-filters"
    }}>{`Step 5: Add US state and Job Title filters`}</h2>
    <Vimeo id={432214569} mdxType="Vimeo" />
    <p>{`With all that done, we can add two more filters: US states and job titles. Our
`}<inlineCode parentName="p">{`App`}</inlineCode>{` component is already set up to use them, so we just have to add them to
the `}<inlineCode parentName="p">{`Controls`}</inlineCode>{` component.`}</p>
    <p>{`We'll start with the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method, then handle the parts I said earlier
would look repetitive.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js
const Controls = () => {
  // ...

    const years = new Set(data.map((d) => d.submit_date.getFullYear())),
      // Insert the line(s) between here...
      jobTitles = new Set(data.map((d) => d.clean_job_title)),
      USstates = new Set(data.map((d) => d.USstate))
    // ...and here.

    return (
      <div>
        <ControlRow
          data={data}
          toggleNames={Array.from(years.values())}
          picked={this.state.year}
          updateDataFilter={this.updateYearFilter}
        />
        // Insert the line(s) between here...
        <ControlRow
          data={data}
          toggleNames={Array.from(jobTitles.values())}
          picked={this.state.jobTitle}
          updateDataFilter={this.updateJobTitleFilter}
        />
        <ControlRow
          data={data}
          toggleNames={Array.from(USstates.values())}
          picked={this.state.USstate}
          updateDataFilter={this.updateUSstateFilter}
          capitalize="true"
        />
        // ...and here.
      </div>
    )
  }
}
`}</code></pre>
    <p>{`Ok, this part is plenty repetitive, too.`}</p>
    <p>{`We created new sets for `}<inlineCode parentName="p">{`jobTitles`}</inlineCode>{` and `}<inlineCode parentName="p">{`USstates`}</inlineCode>{`, then rendered two more
`}<inlineCode parentName="p">{`ControlRow`}</inlineCode>{` elements with appropriate attributes. They get `}<inlineCode parentName="p">{`toggleNames`}</inlineCode>{` for
building the buttons, `}<inlineCode parentName="p">{`picked`}</inlineCode>{` to know which is active, an `}<inlineCode parentName="p">{`updateDataFilter`}</inlineCode>{`
callback, and we tell US states to render capitalized.`}</p>
    <p>{`The implementations of those `}<inlineCode parentName="p">{`updateDataFilter`}</inlineCode>{` callbacks follow the same
pattern as `}<inlineCode parentName="p">{`updateYearFilter`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js

const Controls = () => {
  const [filteredBy, setFilteredBy] = useState({
    year: "*",
    USstate: "*",
    jobTitle: "*",
  })
  const [filterFunctions, setFilter] = useState({
    year: () => true,
    USstate: () => true,
    jobTitle: () => true,
  })

  const updateJobTitleFilter = (jobTitle, reset) => {
    let jobTitleFilter = (d) => d.clean_job_title === jobTitle

    if (reset || !jobTitle) {
      jobTitleFilter = () => true
      jobTitle = "*"
    }

    setFilteredBy((filteredBy) => {
      return { ...filteredBy, jobTitle }
    })
    setFilter((filterFunctions) => {
      return { ...filterFunctions, jobTitle: jobTitleFilter }
    })

    reportUpdateUpTheChain()
  }

  const updateUSstateFilter = (USstate, reset) => {
    let USstateFilter = (d) => d.clean_job_title === USstate

    if (reset || !USstate) {
      USstateFilter = () => true
      USstate = "*"
    }

    setFilteredBy((filteredBy) => {
      return { ...filteredBy, USstate }
    })
    setFilter((filterFunctions) => {
      return { ...filterFunctions, USstate: USstateFilter }
    })

    reportUpdateUpTheChain()
  }

  // ..
}

export default Controls
`}</code></pre>
    <p>{`Yes, they're basically the same as `}<inlineCode parentName="p">{`updateYearFilter`}</inlineCode>{`. The only difference is a
changed `}<inlineCode parentName="p">{`filter`}</inlineCode>{` function and using different keys when setting state.`}</p>
    <p>{`Why separate functions then? No need to get fancy. It would've made the code
harder to read.`}</p>
    <p>{`Our last step is to add these new keys to the `}<inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{`
function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls/index.js

const Controls = () => {
  function reportUpdateUpTheChain() {
    window.location.hash = [
      filteredBy.year,
      filteredBy.USstate,
      filteredBy.jobTitle,
    ].join("-")

    const filter = (d) =>
      filterFunctions.year(d) &&
      filterFunctions.USstate(d) &&
      filterFunctions.jobTitle(d)

    updateDataFilter(filter, filteredBy)
  }
}
`}</code></pre>
    <p>{`We add them to the filter condition with `}<inlineCode parentName="p">{`&&`}</inlineCode>{` and expand the `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{`
argument.`}</p>
    <p>{`Two more rows of filters show up.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/all-filters.png",
        "alt": "All the filters"
      }}></img></p>
    <p>{`👏`}</p>
    <h2 {...{
      "id": "stale-state-is-stale-🤨"
    }}>{`Stale state is stale 🤨`}</h2>
    <Vimeo id={432215286} mdxType="Vimeo" />
    <p>{`A funny thing happened. We were calling `}<inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{` which relies on local state in the component.`}</p>
    <p>{`But that state doesn't update right away. And the state hook provides no callback like the old `}<inlineCode parentName="p">{`this.setState`}</inlineCode>{` used to.`}</p>
    <p><inlineCode parentName="p">{`reportUpdateUpTheChain`}</inlineCode>{` is a side-effect! We have to use `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Controls.js

const Controls = ({ data, updateDataFilter }) => {
  useEffect(() => {
    reportUpdateUpTheChain()
  }, [filteredBy, filterFunctions])
}
`}</code></pre>
    <p>{`You should now have a working data visualization dashboard with user controls. It struggles on certain clicks because of the shortened dataset.`}</p>
    <p>{`If that didn't work, consult the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/eb3533b04e91977ae26ae8e983b93a823d328f87"
      }}>{`diff on GitHub`}</a></p>
    <p>{`Next up - going live 🚀`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      